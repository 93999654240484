// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBqhaB65UHORb7hYERY0aEhTup5rLSArNc",
  authDomain: "triana-singgih.firebaseapp.com",
  projectId: "triana-singgih",
  storageBucket: "triana-singgih.appspot.com",
  messagingSenderId: "506307893292",
  appId: "1:506307893292:web:9f1c4de15c19a479205989",
  measurementId: "G-9QSTC6PFLX"
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);

export const db = getFirestore();

export default firebase