import React, { useState, Fragment } from 'react';
import useGuestUpdate from '@hooks/useGuestUpdate';
// firebase
import { db } from '../../../firebase';
import { collection, doc, writeBatch }  from "firebase/firestore";

function GuestUpload() {
  const { data } = useGuestUpdate();

	const batch = writeBatch(db);

	const handleUploadData = async() => {

		data.forEach((att) => {
			// var docRef = db.collection("col").doc(); //automatically generate unique id
			// batch.set(docRef, doc);
			let attendances = {
				name: att.name,
				phone: att.phone,
				isInvitation: att.isInvitation,
				isAttend: att.isAttend,
				withPartner: att.withPartner,
				lang: att.lang,
				time: new Date(),
			}

			const docRef = doc(collection(db, "attendances"));
			batch.set(docRef, attendances);

			// const docRef = await addDoc(collection(db, "attendances"), attendances)
			// console.log("Document written with ID: ", docRef.id);
		});

		await batch.commit();
  }

	return(
		<Fragment>
			<button onClick={handleUploadData}>Upload</button>
		</Fragment>
	)
}

export default GuestUpload